<section class="flexible">
  <!-- id="bar-section" -->
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="min-[1200px]:w-[40%] flex items-center">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Registrar empleado"
        icon="pi pi-plus"
        severity="danger"
        [rounded]="true"
        (onClick)="openCreateEmployee()"
      />
    </div>

    <div
      class="w-full min-[1200px]:w-[40%] mx-2 flex justify-center lg:justify-start items-center"
    >
      <p-iconField class="w-full lg:w-auto" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="rounded-full w-full lg:w-[200%] bg-grey-color border-none"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Buscar por la identificación o nombre del empleado"
          (input)="pTable.filterGlobal(pSearchInput.value, 'contains')"
        />
      </p-iconField>
    </div>

    <div
      class="min-[1200px]:w-[20%] hidden sm:flex sm:justify-center lg:justify-end place-self-end"
    >
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Descargar reporte"
        icon="pi pi-download"
        severity="danger"
        [rounded]="true"
        [outlined]="true"
        (onClick)="exportToCSV(pTable)"
      />
    </div>
  </div>

  <div class="max-w-[100vw] relative flexible">
    <p-table
      #pTable
      class="absolute inset-0 p-5 pt-0 overflow-y-scroll flexible"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      selectionMode="single"
      [scrollable]="true"
      [columns]="columns"
      [value]="pTableData"
      [exportFilename]="table_name"
      [globalFilterFields]="[
        'usuario',
        'nombre',
        'correo',
        'telefono',
        'especializaciones'
      ]"
      [paginator]="true"
      [alwaysShowPaginator]="true"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Se muestran {last} de {totalRecords}"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) { @if ($index !==
          columns.length - 1) {
          <th [pSortableColumn]="column.field" class="w-[25%]">
            {{ column.header | titlecase }}
            <p-sortIcon [field]="column.field" />
          </th>
          } @else {
          <th class="auto">
            {{ column.header | titlecase }}
          </th>
          } }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-person>
        <tr>
          <td>{{ person.usuario }}</td>
          <td>{{ person.nombre }}</td>
          <td>{{ person.correo }}</td>
          <td>{{ person.telefono }}</td>
          <td>
            <div class="flex gap-2">
              @if (person.especializaciones.length > 0) {
              <p-chip styleClass="py-2 bg-[#ECF6FE]">
                {{ person.especializaciones[0] }}
              </p-chip>
              } @if (person.especializaciones.length > 1) {
              <p-chip styleClass="py-2 bg-[#ECF6FE]">
                {{ person.especializaciones[1] }}
              </p-chip>
              } @if (person.especializaciones.length > 2) {
              <p-chip styleClass="py-2 bg-[#FFFCEB] rounded-full">
                +{{ person.especializaciones.length - 2 }}
              </p-chip>
              }
            </div>
          </td>
          <td class="custom-table-actions">
            <div class="flex items-center gap-x-1">
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="pi pi-eye"
                (onClick)="viewEmployeeInfo(person._id)"
              />
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="pi pi-inbox"
                (onClick)="archiveEmployeeInfo($event, person._id)"
              />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- MARK: Mobile -->
  <div class="h-[60px] block sm:hidden"></div>
  <div
    class="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full py-3 bg-white sm:hidden"
  >
    <p-button
      type="button"
      label="Descargar reporte"
      icon="pi pi-download"
      severity="danger"
      [rounded]="true"
      [outlined]="true"
    />
  </div>
</section>

<!-- MARK: MODALS -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarVisible"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Nuevo empleado</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="createEmployee()"
    >
      <div class="flex items-center justify-center w-full py-5">
        <input
          class="hidden w-full"
          #fileInputImage
          type="file"
          (change)="uploadFileImage($event)"
          accept="image/*"
        />

        @if (!!previewImage || !!employee?.url_image) {
        <img
          role="button"
          class="object-contain aspect-square w-[150px] h-[150px]"
          alt="perfil"
          [src]="previewImage ?? employee?.url_image"
          (click)="fileInputImage.click()"
        />
        } @else {
        <div
          class="flex items-center justify-center w-[150px] h-[150px]"
          (click)="fileInputImage.click()"
        >
          <div
            role="button"
            class="relative flex items-center justify-center rounded-full w-[100px] h-[100px] bg-[#F5F5F5]"
          >
            <span class="scale-[200%] pi pi-user"></span>
            <div
              class="absolute bottom-0 right-0 rounded-full flex justify-center items-center bg-[#FEF6F7] w-[40px] h-[40px] drop-shadow-sm ring-5 ring-white"
            >
              <span class="scale-150 pi pi-camera"></span>
            </div>
          </div>
        </div>
        }
      </div>

      <div class="mb-5">
        <label for="name">Pasaporte</label>
        <div
          class="flex justify-center p-2 bg-blue-100 border-4 border-gray-500 border-dashed rounded-xl"
        >
          <img
            role="button"
            [src]="previewPasaporte ?? employee?.url_pasaporte"
            alt=""
            class="object-contain aspect-square h-[200px] w-full"
            (click)="fileInputPasaporte.click()"
          />
          <input
            class="w-full"
            #fileInputPasaporte
            type="file"
            (change)="uploadFilePasaporte($event)"
            style="display: none"
            accept="image/*"
          />
        </div>
      </div>

      <div class="flex flex-col items-center flex-1 w-full my-5 gap-y-10">
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_employee['usuario']"
            id="identification"
            type="text"
            name="identification"
          />
          <label for="identification">Identificación</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_employee['nombre']"
            id="name"
            type="text"
            name="name"
          />
          <label for="name">Nombres</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_employee['apellido']"
            id="last"
            type="text"
            name="last"
          />
          <label for="last">Apellidos</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_employee['correo']"
            id="email"
            type="text"
            name="email"
          />
          <label for="email">Correo electrónico</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_employee['telefono']"
            id="phone"
            type="text"
            name="phone"
          />
          <label for="phone">Teléfono</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            placeholder="Agregar presionando Enter"
            id="specialty"
            type="text"
            name="specialty"
            (focus)="onFocusInfo()"
            (keydown.enter)="onEnterAddSpecialty($event)"
          />
          <label for="specialty">Especialidad</label>
        </p-floatLabel>
      </div>
      @if(new_employee.especialidades.length > 0) {
      <div class="flex flex-wrap items-center justify-end w-full gap-2 my-5">
        @for (specialty of new_employee.especialidades; track $index) {
        <button type="button" (click)="removeSpecialty(specialty)">
          <p-chip styleClass="py-2 bg-[#ECF6FE]">
            {{ specialty }}
          </p-chip>
        </button>
        }
      </div>
      }

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          type="submit"
          label="Registrar empleado"
          severity="danger"
          [rounded]="true"
          [loading]="isCreateInProgress"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>
