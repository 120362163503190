<section class="flex flex-col flex-1">
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="min-[1200px]:w-[40%] flex items-center justify-between">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Nuevo ingreso"
        icon="pi pi-plus"
        severity="danger"
        [rounded]="true"
        (onClick)="openCreateIncome()"
      />
      <p-calendar
        class="custom-p-calendar"
        [(ngModel)]="searchDate"
        [showIcon]="true"
        inputId="icondisplay"
        dateFormat="dd-mm-yy"
        appendTo="body"
        [showClear]="true"
        (onClear)="loadTable()"
        (onSelect)="filterByDate($event, 'created_at')"
      />
    </div>

    <div
      class="w-full min-[1200px]:w-[40%] mx-2 flex justify-center lg:justify-start items-center"
    >
      <p-iconField class="w-full lg:w-auto" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="rounded-full w-full lg:w-[200%] bg-grey-color border-none"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Identificación o nombre"
          (input)="pTable.filterGlobal(pSearchInput.value, 'contains')"
        />
      </p-iconField>
    </div>

    <div
      class="min-[1200px]:w-[20%] hidden sm:flex sm:justify-center lg:justify-end place-self-end"
    >
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Descargar reporte"
        icon="pi pi-download"
        severity="danger"
        [rounded]="true"
        [outlined]="true"
        (onClick)="exportToCSV(pTable)"
      />
    </div>
  </div>

  <div class="max-w-[100vw] relative flexible">
    <p-table
      #pTable
      class="absolute inset-0 p-5 pt-0 overflow-y-scroll flexible"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      selectionMode="single"
      [scrollable]="true"
      [columns]="columns"
      [value]="pTableData"
      [exportFilename]="table_name"
      [globalFilterFields]="[
        '_id',
        'usuario',
        'empresa',
        'concepto',
        'monto',
        'created_at'
      ]"
      [paginator]="true"
      [alwaysShowPaginator]="true"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Se muestran {last} de {totalRecords}"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) { @if ($index !==
          columns.length - 1) {
          <th [pSortableColumn]="column.field" class="w-[25%]">
            {{ column.header | titlecase }}
            <p-sortIcon [field]="column.field" />
          </th>
          } @else {
          <th class="auto">
            {{ column.header | titlecase }}
          </th>
          } }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-income>
        <tr>
          <td>{{ income._id | padNumber }}</td>
          <td>{{ income.usuario }}</td>
          <td>{{ income.empresa }}</td>
          <td>{{ income.concepto }}</td>
          <td>{{ income.monto | currency }}</td>
          <td>{{ income.dias }}</td>
          <td>{{ income.created_at | date : 'dd-MM-yyyy' }}</td>
          <td class="custom-table-actions">
            <div class="flex items-center justify-evenly">
              <p-button
              styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
              icon="pi pi-eye"
              (onClick)="viewIncome(income)"
              />
              <p-button
              styleClass="bg-[#F5F5F5] py-1 px-6 border-none rounded-full"
              icon="pi pi-trash text-red-color font-bold text-lg"
              (onClick)="deleteIncome($event, income)"
              />
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="h-[60px] block sm:hidden"></div>

  <div
    class="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full py-3 bg-white sm:hidden"
  >
    <p-button
      type="button"
      label="Descargar reporte"
      icon="pi pi-download"
      severity="danger"
      [rounded]="true"
      [outlined]="true"
    />
  </div>
</section>

<!-- MARK: MODALS -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-dialog
  styleClass="min-w-[20vw]"
  [(visible)]="displayVisible"
  header="Otros ingresos"
  [modal]="true"
  [focusOnShow]="false"
  (onHide)="incomeToDisplay = undefined; displayVisible = false"
>
  <div class="flex flex-col items-center justify-center px-3">
    <p-chip
      styleClass="px-5 flex justify-between items-center bg-aqua-color"
      role="button"
    >
      <p-avatar
        [label]="
          (incomeToDisplay?.contrato?.cuenta?.persona?.nombre &&
            (incomeToDisplay?.contrato?.cuenta?.persona?.nombre)![0]) ||
          ''
        "
        styleClass="mr-2 bg-[gainsboro] rounded-full"
        size="xlarge"
      />
      <p class="hidden md:block">
        <span>{{
          incomeToDisplay?.contrato?.cuenta?.persona?.nombre +
            ' ' +
            incomeToDisplay?.contrato?.cuenta?.persona?.apellido
        }}</span
        ><br />
        <span class="text-sm capitalize">{{
          incomeToDisplay?.contrato?.cuenta?.persona?.cedula
        }}</span>
      </p>
    </p-chip>
    <p class="py-5 text-5xl">
      <span>{{ incomeToDisplay?.pago | currency }}</span>
    </p>
    <p class="text-lg">
      <span class="pi pi-hashtag"></span>
      <span class="mx-5">{{ incomeToDisplay?._id | padNumber }}</span>
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-building"></span> Empresa:</span>
      <span>{{ incomeToDisplay?.contrato?.empresa?.nombre }}</span>
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-calendar"></span> Fecha de registro:</span>
      <span>{{ incomeToDisplay?.created_at | date : 'dd-MM-yyyy' }}</span>
    </p>
    <p class="flex justify-between w-full my-3 text-lg">
      <span><span class="mx-2 pi pi-receipt"></span> Concepto</span>
      <span>{{ incomeToDisplay?.concepto }}</span>
    </p>
  </div>
</p-dialog>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarVisible"
  position="right"
>
  <ng-template pTemplate="header">
    <h3>Nuevo ingreso</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="createIncome()"
    >
      <div class="flex flex-col items-center flex-1 w-full gap-y-10">
        <div
          class="flex items-center w-full rounded-full py-1 px-3 bg-[gainsboro]"
        >
          <span class="pi pi-calendar"></span>
          <p class="w-full mx-4 text-left">{{ today }}</p>
        </div>
        <div class="w-full">
          <p-floatLabel class="w-full">
            <input
              [(ngModel)]="employeeInput"
              class="w-full"
              pInputText
              id="employee"
              type="text"
              name="employee"
              (click)="companiesFiltered = []"
              (input)="onEmployeeInput($event)"
            />
            <label for="employee">Empleado</label>
          </p-floatLabel>
          @if (employeesFiltered.length > 0) {
          <div class="relative w-full">
            <p-listbox
              class="absolute inset-0 w-full z-[1000]"
              styleClass="w-full"
              [options]="employeesFiltered"
              (onChange)="changeEmployee($event.value)"
            />
          </div>
          }
        </div>
        <div class="w-full">
          <p-floatLabel class="w-full">
            <input
              [(ngModel)]="companyInput"
              class="w-full"
              pInputText
              id="company"
              type="text"
              name="company"
              list="companiesFiltered"
              (click)="companiesFiltered = employee_companies"
              (input)="onCompanyInput($event)"
            />
            <label for="company">Empresa</label>
          </p-floatLabel>
          @if (companiesFiltered.length > 0) {
          <div class="relative w-full">
            <p-listbox
              class="absolute inset-0 w-full z-[1000]"
              styleClass="w-full"
              [options]="companiesFiltered"
              optionLabel="nombre"
              (onChange)="changeCompany($event.value['_id'])"
            />
          </div>
          }
        </div>
        <p-floatLabel class="w-full">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="new_income['dias']"
            [showButtons]="true"
            buttonLayout="horizontal"
            inputId="minmax"
            mode="decimal"
            [min]="0"
            [max]="maxDays"
            spinnerMode="horizontal"
            incrementButtonIcon="pi pi-plus"
            decrementButtonIcon="pi pi-minus"
            name="days"
            id="days"
          />
          <label for="days">Días presupestados</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <p-inputNumber
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="new_income['monto']"
            mode="currency"
            currency="USD"
            [min]="0"
            [maxlength]="7"
            name="amount"
            inputId="amount"
          />
          <label for="amount">Monto</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            class="w-full"
            pInputText
            [(ngModel)]="new_income['concepto']"
            id="concept"
            type="text"
            name="concept"
          />
          <label for="concept">Concepto o detalle</label>
        </p-floatLabel>
      </div>

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          label="Registrar ingreso"
          severity="danger"
          [rounded]="true"
          type="submit"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>
