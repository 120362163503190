<section class="flexible">
  <modal-component></modal-component>
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="min-[1200px]:w-[40%] flex items-center justify-between">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Nuevo registro"
        icon="pi pi-plus"
        severity="danger"
        [rounded]="true"
        (onClick)="openTimeRecord()"
      />
      <p-calendar
        class="custom-p-calendar"
        [(ngModel)]="searchDate"
        [showIcon]="true"
        inputId="icondisplay"
        dateFormat="dd-mm-yy"
        appendTo="body"
        [showClear]="true"
        (onClear)="loadTable()"
        (onSelect)="filterByDate($event, 'created_at')"
      />
    </div>

    <div
      class="w-full min-[1200px]:w-[40%] mx-2 flex justify-center lg:justify-start items-center"
    >
      <p-iconField class="w-full lg:w-auto" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="rounded-full w-full lg:w-[200%] bg-grey-color border-none"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Identificación o nombre"
          (input)="pTable.filterGlobal(pSearchInput.value, 'contains')"
        />
      </p-iconField>
    </div>

    <div
      class="min-[1200px]:w-[20%] hidden sm:flex sm:justify-center lg:justify-end place-self-end"
    >
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Descargar reporte"
        icon="pi pi-download"
        severity="danger"
        [rounded]="true"
        [outlined]="true"
        (onClick)="exportToCSV(pTable)"
      />
    </div>
  </div>

  <div class="max-w-[100vw] relative flexible">
    <p-table
      #pTable
      class="absolute inset-0 p-5 pt-0 overflow-y-scroll flexible"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      selectionMode="single"
      [scrollable]="true"
      [columns]="columns"
      [value]="pTableData"
      [exportFilename]="table_name"
      [globalFilterFields]="['cedula', 'usuario', 'empresa', 'created_at']"
      [paginator]="true"
      [alwaysShowPaginator]="true"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Se muestran {last} de {totalRecords}"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) { @if ($index !==
          columns.length - 1) {
          <th [pSortableColumn]="column.field" class="w-[25%]">
            {{ column.header | titlecase }}
            <p-sortIcon [field]="column.field" />
          </th>
          } @else {
          <th class="auto">
            {{ column.header | titlecase }}
          </th>
          } }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-record>
        <tr>
          <td>{{ record.cedula }}</td>
          <td>{{ record.usuario }}</td>
          <td>{{ record.empresa }}</td>
          <td>{{ record.created_at | date : 'dd-MM-yyyy' }}</td>
          <td class="text-[limegreen]">{{ record.entrada }}</td>
          <td class="text-[red]">{{ record.salida }}</td>
          <td>{{ record.cantidad }}</td>
          <td class="custom-table-actions">
            <div class="flex items-center gap-x-1">
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="pi pi-eye"
                (onClick)="showDialog(record)"
              />
              @if(today.getFullYear() == record.created_at.getFullYear() &&
              today.getMonth() == record.created_at.getMonth()) {
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="pi pi-pen-to-square"
                (onClick)="openTimeRecord(record)"
              />
              <p-button
                styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
                icon="pi pi-trash"
                (onClick)="deleteTimeRecord($event, record)"
              />
              }
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="h-[60px] block sm:hidden"></div>

  <div
    class="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full py-3 bg-white sm:hidden"
  >
    <p-button
      type="button"
      label="Descargar reporte"
      icon="pi pi-download"
      severity="danger"
      [rounded]="true"
      [outlined]="true"
    />
  </div>
</section>

<!-- MARK: MODALS -->
<div class="flex items-center justify-center">
  <p-confirmDialog />
</div>

<p-sidebar
  styleClass="w-[350px] flex flex-col flex-1"
  [(visible)]="sidebarTimeRecord"
  position="right"
  (onHide)="cleanInputs()"
>
  <ng-template pTemplate="header">
    <h3>{{ !!!time_record ? 'Nuevo' : 'Actualizar' }} registro de hora</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form
      class="flex flex-col justify-around flex-1 px-5 pb-10"
      (submit)="saveTimeRecord()"
    >
      <div class="flex flex-col flex-1 w-full pt-5 gap-y-10">
        @if (!!!time_record) {
        <div class="w-full">
          <p-floatLabel class="w-full">
            <input
              [(ngModel)]="employeeInput"
              class="w-full"
              pInputText
              id="employee"
              type="text"
              name="employee"
              (click)="companiesFiltered = []"
              (input)="onEmployeeInput($event)"
            />
            <label for="employee">Empleado</label>
          </p-floatLabel>
          @if (employeesFiltered.length > 0) {
          <div class="relative w-full">
            <p-listbox
              class="absolute inset-0 w-full z-[1000]"
              styleClass="w-full"
              [options]="employeesFiltered"
              (onChange)="changeEmployee($event.value)"
            />
          </div>
          }
        </div>
        <div class="w-full">
          <p-floatLabel class="w-full">
            <input
              [(ngModel)]="companyInput"
              class="w-full"
              pInputText
              id="company"
              type="text"
              name="company"
              list="companiesFiltered"
              (click)="companiesFiltered = employee_companies"
              (input)="onCompanyInput($event)"
            />
            <label for="company">Empresa</label>
            <!-- <datalist id="companiesFiltered">
              <option *ngFor="let c of companiesFiltered" [value]="c.nombre">{{c.nombre}}</option>
            </datalist> -->
          </p-floatLabel>
          @if (companiesFiltered.length > 0) {
          <div class="relative w-full">
            <p-listbox
              class="absolute inset-0 w-full z-[1000]"
              styleClass="w-full"
              [options]="companiesFiltered"
              optionLabel="nombre"
              (onChange)="changeCompany($event.value['_id'])"
            />
          </div>
          }
        </div>
        }

        <p-floatLabel class="w-full">
          <p-calendar
            class="w-full"
            styleClass="w-full"
            [showButtonBar]="true"
            [showIcon]="true"
            [(ngModel)]="inputDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [selectOtherMonths]="false"
            dateFormat="dd-mm-yy"
            name="created_at"
            (onShow)="alertCreatedAt()"
            (onSelect)="new_time_record['created_at'] = $event.toString()"
          />
          <label for="created_at">Fecha de registro</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <input
            [(ngModel)]="new_time_record['cantidad']"
            class="w-full"
            pInputText
            id="quantity"
            type="text"
            name="quantity"
            [disabled]="true"
          />
          <label for="quantity">Cantidad de horas</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <p-calendar
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="iniDate"
            [timeOnly]="true"
            name="checkin"
            (ngModelChange)="updateQuantity()"
          />
          <label for="checkin">Hora de entrada</label>
        </p-floatLabel>
        <p-floatLabel class="w-full">
          <p-calendar
            class="w-full"
            styleClass="w-full"
            [(ngModel)]="endDate"
            [timeOnly]="true"
            name="checkout"
            (ngModelChange)="updateQuantity()"
          />
          <label for="checkout">Hora de salida</label>
        </p-floatLabel>
      </div>

      <div class="flex items-center justify-center w-full h-[7vh]">
        <p-button
          class="w-[70%]"
          styleClass="w-full"
          label="{{ !!!time_record ? 'Nuevo' : 'Actualizar' }} registro"
          severity="danger"
          [rounded]="true"
          type="submit"
        />
      </div>
    </form>
  </ng-template>
</p-sidebar>
