import { Location } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import {
  Storage,
  getDownloadURL,
  ref,
  uploadBytes,
} from '@angular/fire/storage'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router'
import { ApiDepartments } from '@api/api.companies'
import { ApiContract } from '@api/api.contract'
import { ApiPersonnelManagement } from '@api/api.personnel-management'
import { Company, Department } from '@interfaces/companies.interface'
import { EmployeeCompany, NewContract } from '@interfaces/contract.interface'
import { Person } from '@interfaces/personnel-management.interface'
import { ApiService } from '@services/api.service'
import { CompaniesService } from '@services/companies.service'
import { PersonnelManagementService } from '@services/personnel-management.service'
import { ToastService } from '@services/toast.service'
import { ConfirmationService } from 'primeng/api'
import { CalendarModule } from 'primeng/calendar'
import { ChipModule } from 'primeng/chip'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { DropdownModule } from 'primeng/dropdown'
import { FloatLabelModule } from 'primeng/floatlabel'
import { IconFieldModule } from 'primeng/iconfield'
import { InputIconModule } from 'primeng/inputicon'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputTextModule } from 'primeng/inputtext'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { SidebarModule } from 'primeng/sidebar'
import { TableModule } from 'primeng/table'
import { mergeMap } from 'rxjs'

@Component({
  selector: 'app-employee-info',
  standalone: true,
  imports: [
    RouterOutlet,
    CalendarModule,
    FormsModule,
    IconFieldModule,
    FloatLabelModule,
    InputIconModule,
    InputTextModule,
    InputNumberModule,
    TableModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    ChipModule,
    SidebarModule,
    DropdownModule,
    InputSwitchModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './employee-info.component.html',
  styleUrl: './employee-info.component.scss',
  host: {
    class: 'flexible',
  },
})
export class EmployeeInfoComponent implements OnInit {
  employees: Person[] = []
  employee: Person | undefined
  employee_companies: EmployeeCompany[] = []
  employee_companies_filtered: EmployeeCompany[] = []
  checked = true

  companies: Company[] = []
  company: Company | undefined
  departments: Department[] = []
  contracts: any[] = []
  new_contract: NewContract = {
    valor: 0,
    descanso: 0,
    empresa_id: -1,
    cuenta_id: -1,
    departamento_id: -1,
  }

  isSpecialtiesOpen = false
  sidebarEmployee = false
  sidebarEnrollment = false
  isCreateInProgress = false

  companyToDisplay: EmployeeCompany | undefined

  fileImage: any = ''
  filePasaporte: any = ''

  previewImage: string | undefined = undefined
  previewPasaporte: string | undefined = undefined

  especialidades: string[] = []
  updated_correo_employee: string | undefined = undefined
  updated_nombre_employee: string | undefined = undefined
  updated_apellido_employee: string | undefined = undefined
  updated_cedula_employee: string | undefined = undefined
  updated_telefono_employee: string | undefined = undefined
  event: any

  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private srvAPI: ApiService,
    private srvPersonnelManagement: PersonnelManagementService,
    private srvCompanies: CompaniesService,
    private confirmationService: ConfirmationService,
    private storage: Storage,
    private srvToast: ToastService,
  ) {
    this.srvPersonnelManagement.employees$.subscribe((employees: any) => {
      this.employees = employees['active'] as Person[]

      const employeeID = +this.route.snapshot.params['employee']
      this.employee = this.employees.find(
        (employee: Person) => employee.cuenta._id === employeeID,
      )
      if (!!this.employee) {
        this.srvAPI
          .get(
            `${ApiContract.obtenerEmpresasContratoDeEmpleado}/${this.employee?.cuenta._id}`,
          )
          .subscribe((response: any) => {
            this.employee_companies = response['data'][
              'empresas'
            ] as EmployeeCompany[]
            this.employee_companies_filtered = response['data'][
              'empresas'
            ] as EmployeeCompany[]
          })
      }
    })
    this.srvCompanies.companies$.subscribe((companies: any) => {
      this.companies = companies['active'] as Company[]
    })
    this.srvPersonnelManagement.company
      .asObservable()
      .subscribe((company: EmployeeCompany | undefined) => {
        this.companyToDisplay = company
      })
  }

  ngOnInit() {
    this.srvPersonnelManagement.loadData()
    this.srvCompanies.loadData()
  }

  filterCompanies(input: string) {
    if (input === '') this.employee_companies_filtered = this.employee_companies

    this.employee_companies_filtered = this.employee_companies.filter(
      (company) =>
        company.nombre.toLowerCase().includes(input.toLowerCase()) ||
        (!!company.supervisor &&
          company.supervisor.toLowerCase().includes(input.toLowerCase())) ||
        company.contrato.departamento.nombre
          .toLowerCase()
          .includes(input.toLowerCase()),
    )
  }

  goBack() {
    this.companyToDisplay = undefined
    this.srvPersonnelManagement.company.next(undefined)
    this.location.back()
  }

  archiveEmployeeInfo(event: Event, deparment: Department) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Esta acción desactivará el departamento',
      header: 'Archivar el Departamento',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Archivar',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .patch(`${ApiDepartments.archivar}/${deparment._id}/1`, {})
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se archivó el departamento',
              })
              this.srvPersonnelManagement.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `${error.error.statusCode} ${error.error.error}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  toggleSpecialties() {
    this.isSpecialtiesOpen = !this.isSpecialtiesOpen
  }

  goToEmployeeContract(company: EmployeeCompany) {
    if (!!!this.employee) return

    this.companyToDisplay = company
    this.srvPersonnelManagement.employee.next(this.employee)
    this.srvPersonnelManagement.company.next(company)
    this.router.navigateByUrl(
      `/dashboard/personnel-management/${this.employee.cuenta._id}/(employee-contract:${company.nombre})`,
    )
  }

  deleteEmployeeContract(event: Event, contrato_id: number) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Esta acción no puede deshacerse',
      header: 'Remover contrato del empleado',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Remover',
      rejectLabel: 'Cancelar',
      acceptIcon: 'none',
      rejectIcon: 'none',
      acceptButtonStyleClass: 'bg-red-color border-none rounded-full',
      rejectButtonStyleClass: 'p-button-text text-red-color rounded-full',
      accept: () => {
        this.srvAPI
          .delete(`${ApiContract.removerContrato}/${contrato_id}`)
          .subscribe({
            next: (response: any) => {
              this.srvToast.success({
                title: response.status,
                message: 'Se eliminó el contrato',
              })
              this.srvPersonnelManagement.loadData()
            },
            error: (error) => {
              this.srvToast.error({
                title: `Error ${error.status}`,
                message: error.error.message,
              })
            },
          })
      },
    })
  }

  handleDesableEmployee(event: any, contrato_id: number) {
    this.srvAPI
      .patch(`${ApiContract.cambiarEstado}/${contrato_id}`, {
        estado: event.checked ? 1 : 0,
      })
      .subscribe((response: any) => {
        this.srvPersonnelManagement.loadData()
      })
  }

  openEditEmployee() {
    this.updated_correo_employee = this.employee?.correo || undefined
    this.especialidades =
      this.employee?.personaEspecialidades.map(
        (especialidad) => especialidad.especialidad.nombre,
      ) || []
    this.sidebarEmployee = true
  }

  onEnterAddSpecialty(event: Event) {
    event.preventDefault()
    const input = event.target as HTMLInputElement
    if (!!!input.value || input.value === '') {
      this.srvToast.info({
        title: 'Entrada no válida',
        message: 'Ingrese una especialidad',
      })
      return
    }

    this.especialidades.push(input.value.trim())
    input.value = ''
  }

  removeSpecialty(specialty: string) {
    const index = this.especialidades.indexOf(specialty)
    if (index !== -1) this.especialidades.splice(index, 1)
  }

  async saveChanges() {
    this.isCreateInProgress = true

    let urlObjImage: URL | undefined = undefined
    let urlObjPasaporte: URL | undefined = undefined

    // image upload
    try {
      if (!!this.fileImage) {
        const storageRefImage = ref(
          this.storage,
          `persona/${this.employee?._id}/perfil.${
            this.fileImage.name.split('.').pop() || ''
          }`,
        )
        const snapshotImage = await uploadBytes(storageRefImage, this.fileImage)
        const downloadURLImage = await getDownloadURL(snapshotImage.ref)
        urlObjImage = new URL(downloadURLImage)
      }

      if (!!this.filePasaporte) {
        const storageRefPasaporte = ref(
          this.storage,
          `persona/${this.employee?._id}/pasaporte.${
            this.filePasaporte.name.split('.').pop() || ''
          }`,
        )

        const snapshotPasaporte = await uploadBytes(
          storageRefPasaporte,
          this.filePasaporte,
        )
        const downloadURLPasaporte = await getDownloadURL(snapshotPasaporte.ref)
        urlObjPasaporte = new URL(downloadURLPasaporte)
      }
    } catch (error) {}

    // update employee with images
    this.srvAPI
      .patch(
        `${ApiPersonnelManagement.actualizar_persona}/${this.employee?.cuenta.usuario}`,
        {
          nombre: this.updated_nombre_employee
            ? this.updated_nombre_employee
            : this.employee?.nombre,
          apellido: this.updated_apellido_employee
            ? this.updated_apellido_employee
            : this.employee?.apellido,
          telefono: this.updated_telefono_employee
            ? this.updated_telefono_employee
            : this.employee?.telefono,
          usuario: this.updated_cedula_employee
            ? this.updated_cedula_employee
            : this.employee?.cuenta.usuario,
          correo: this.updated_correo_employee,
          url_image: !!urlObjImage
            ? urlObjImage.href
            : this.employee?.url_image,
          url_pasaporte: !!urlObjPasaporte
            ? urlObjPasaporte.href
            : this.employee?.url_pasaporte,
        },
      )
      .pipe(
        mergeMap((response) =>
          // update employee with specialties
          this.srvAPI.post(
            `${ApiPersonnelManagement.asignarEspecialidades}/${
              (response as { data: { persona: Person } }).data.persona._id
            }`,
            {
              especialidades: this.especialidades,
            },
          ),
        ),
      )
      .subscribe({
        next: (response: any) => {
          this.srvToast.success({
            title: response.status,
            message: 'El empleado fue actualizado',
          })
          this.srvPersonnelManagement.loadData()
          this.router.navigateByUrl(
            `dashboard/personnel-management/${this.employee?.cuenta._id}`,
          )

          this.sidebarEmployee = false
          this.isCreateInProgress = false
        },
        error: (error) => {
          this.srvToast.error({
            title: `${error.error.statusCode} ${error.error.error}`,
            message: error.error.message,
          })
          this.isCreateInProgress = false
        },
      })
  }

  uploadFileImage(event: any) {
    this.fileImage = event.target.files[0]
    this.previewImage = URL.createObjectURL(this.fileImage)
  }

  uploadFilePasaporte(event: any) {
    this.filePasaporte = event.target.files[0]
    this.previewPasaporte = URL.createObjectURL(this.filePasaporte)
  }

  openEmployeeEnrollment() {
    if (!!!this.employee) return

    this.sidebarEnrollment = true
    this.new_contract.cuenta_id = this.employee.cuenta._id
  }

  changeCompanyAndLoadDepartments(companyID: number) {
    if (this.companies.length > 0) {
      this.company = this.companies.find(
        (company: Company) => company._id === companyID,
      )

      if (!!this.company) {
        this.new_contract.empresa_id = this.company._id
        this.departments =
          this.company?.departamentos.filter((dep) => dep.archivado == 0) || []
        if (this.departments.length <= 0)
          this.srvToast.warn({
            title: `${this.company?.nombre.toUpperCase()} sin departamentos`,
            message: 'Debe crear un departamento',
          })
      }
    }
  }

  createContract() {
    if (!!!this.employee) return

    this.srvAPI.post(ApiContract.crear, this.new_contract).subscribe({
      next: (response: any) => {
        this.srvToast.success({
          title: response.status,
          message: 'Se asignó el empleado a la empresa',
        })
        this.srvPersonnelManagement.loadData()
        this.sidebarEnrollment = false
      },
      error: (error) => {
        this.srvToast.error({
          title: `${error.status} ${error.error.cause}`,
          message: error.error.message,
        })
      },
    })
  }

  onChangeCorreo(event: Event) {
    this.updated_correo_employee = (event.target as HTMLInputElement).value
  }

  onChangeTelefono(event: Event) {
    this.updated_telefono_employee = (event.target as HTMLInputElement).value
  }

  onChangeNombres(event: Event) {
    this.updated_nombre_employee = (event.target as HTMLInputElement).value
  }

  onChangeApellidos(event: Event) {
    this.updated_apellido_employee = (event.target as HTMLInputElement).value
  }

  onChangeCedula(event: Event) {
    this.updated_cedula_employee = (event.target as HTMLInputElement).value
  }
}
