<section class="flex flex-col flex-1">
  <div
    class="flex flex-row-reverse items-center justify-between w-full px-1 py-3 mb-5 lg:px-5 sm:justify-center min-[500px]:flex-row"
  >
    <div class="flex items-center justify-center w-full">
      <p-iconField class="w-full lg:w-auto" iconPosition="left">
        <p-inputIcon styleClass="pi pi-search" />
        <input
          class="rounded-full w-full lg:w-[200%] bg-grey-color border-none"
          pInputText
          #pSearchInput
          type="text"
          placeholder="Identificación o nombre"
          (input)="pTable.filterGlobal(pSearchInput.value, 'contains')"
        />
      </p-iconField>
    </div>

    <div class="flex justify-end place-self-end">
      <p-button
        class="hidden sm:block"
        styleClass="text-nowrap py-2"
        type="button"
        label="Descargar reporte"
        icon="pi pi-download"
        severity="danger"
        [rounded]="true"
        [outlined]="true"
        (onClick)="exportToCSV(pTable)"
      />
    </div>
  </div>

  <div class="max-w-[100vw] relative flexible">
    <p-table
      #pTable
      class="absolute inset-0 p-5 pt-0 overflow-y-scroll flexible"
      styleClass="ring-[1px] ring-[gainsboro] rounded-[10px] text-nowrap my-2"
      tableStyleClass="min-w-[10px]"
      selectionMode="single"
      [scrollable]="true"
      [columns]="columns"
      [value]="pTableData"
      [exportFilename]="table_name"
      [globalFilterFields]="[
        'cedula',
        'usuario',
        'empresa',
        'telefono',
        'estado',
        'created_at',
        'deleted_at',
        'pago',
        'ingresos',
        'egresos',
        'total',
        'fecha_pago'
      ]"
      [paginator]="true"
      [alwaysShowPaginator]="true"
      [rows]="10"
      [rowsPerPageOptions]="[10, 25, 50]"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Se muestran {last} de {totalRecords}"
    >
      <ng-template pTemplate="header">
        <tr>
          @for (column of columns; track $index) {
          <th [pSortableColumn]="column.field" class="w-[25%]">
            {{ column.header | titlecase }}
            <p-sortIcon [field]="column.field" />
          </th>
          }
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-report>
        <tr>
          <td>{{ report.cedula }}</td>
          <td>{{ report.usuario }}</td>
          <td>{{ report.empresa }}</td>
          <td>{{ report.telefono }}</td>
          <td class="custom-table-actions">
            <div
              class="flex items-center ring-[1px] ring-[gainsboro] p-1 rounded-md"
            >
              <span
                class="w-[5px] h-[5px] mx-1 rounded-full {{
                  report.estado == 'Activo' ? 'bg-[limegreen]' : 'bg-[red]'
                }}"
              ></span>
              <span class="mx-1">{{ report.estado }}</span>
            </div>
          </td>
          <td>{{ report.created_at | date : 'dd-MM-yyyy' }}</td>
          <td class="{{ report.deleted_at ? '' : 'text-[gainsboro]' }}">
            {{ (report.deleted_at | date : 'dd-MM-yyyy') || 'ninguno' }}
          </td>
          <td class="pl-0 custom-table-actions">
            <p-button
              styleClass="py-2 px-5 border-none rounded-full {{
                report.pago == 'Pagado'
                  ? 'bg-[#EFFBF1] text-[#4CAF50]'
                  : 'bg-[#FFFCEB] text-[#E5C100]'
              }}"
              [label]="report.pago"
              (onClick)="
                goToReport(report.cuenta_id, report.empresa, report.created_at)
              "
            />
          </td>
          <td
            class="{{
              report.egresos ? 'text-[limegreen]' : 'text-[gainsboro]'
            }}"
          >
            {{ (report.egresos | currency) || 'ninguno' }}
          </td>
          <td class="{{ report.ingresos ? 'text-[red]' : 'text-[gainsboro]' }}">
            {{ (report.ingresos | currency) || 'ninguno' }}
          </td>
          <td class="{{ report.total ? '' : 'text-[gainsboro]' }}">
            {{ (report.total | currency) || 'ninguno' }}
          </td>
          <td class="{{ report.fecha_pago ? '' : 'text-[gainsboro]' }}">
            {{ (report.fecha_pago | date : 'dd-MM-yyyy') || 'ninguno' }}
          </td>
          <!-- <td class="flex items-center gap-x-1 custom-table-actions">
            <p-button
              styleClass="bg-[gainsboro] py-2 px-5 border-none rounded-full"
              icon="pi pi-eye"
              (onClick)="viewCompanyInfo(report.nombre)"
            /> 
          </td>
          -->
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="h-[60px] block sm:hidden"></div>

  <div
    class="absolute bottom-0 left-0 right-0 flex items-center justify-center w-full py-3 bg-white sm:hidden"
  >
    <p-button
      type="button"
      label="Descargar reporte"
      icon="pi pi-download"
      severity="danger"
      [rounded]="true"
      [outlined]="true"
    />
  </div>
</section>
